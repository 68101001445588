<template>
    <div class="row">
        <div class="col-lg-6 col-12 h5 p-4 custom_div">
            Withdrawals pending for approval
            <span class="claim_tp px-5 py-3 ml-5" style="margin-left:19px !important;">
                {{withdrawal_request_pending}}
            </span>
        </div>
        <div class="col-lg-6 col-12 h5 p-4 custom_div">
            Total number of withdrawals
            <span class="claim_tp px-5 py-3 ml-5" style="margin-left:19px !important;">
                {{total_withdrawal}}
            </span>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name:'TotalWithdrawal',
    data(){
        return{
            totalWithdrawal: 0,
            pendingWithdrawal: 0,
        }
    },
    computed: {
    ...mapGetters(["total_withdrawal","withdrawal_request_pending"]),
  },
  created(){
    this.asignValues();
  },
  watch:{
    total_claim(){
      this.asignValues();
      },
  },
  methods:{
    asignValues(){
      this.totalWithdrawal = this.total_withdrawal;
      this.pendingWithdrawal = this.withdrawal_request_pending;
      },
    }
}
</script>
<style scoped>
.claim_tp{
    background-color: #b9e2f1 !important;
    border-radius: 10px;
}

</style>
