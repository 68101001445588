<template>
      <v-container>
         <v-card outlined elevation="0" class="mb-1">
          <div class="mt-4 mb-0">
            <ItcannerHeader/>
          </div>
        </v-card>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
            <TotalWithdrawal :totalWithdrawal="totalWithdrawal" :pendingWithdrawal="pendingWithdrawal" />
           <v-card-title
            class="display-1 text-color text--darken-3 font-weight-bold"
            >
                My Withdrawals
            </v-card-title>
            <WalletPopUp v-if="WalletFilterPopUp.filter" :filter-data="WalletFilterPopUp" @WalletFilterPopUp='getName'></WalletPopUp>
            <v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                class="elevation-1"
            >
                <template v-slot:top>
                  <v-row
                    no-gutters
                  >
                    <v-col
                    class="text-align-right"
                    cols="12">
                      <v-icon class="pa-4 font-weight-bold" size="30px" @click="openFilterPopUp">
                          mdi-filter-variant
                      </v-icon>
                    </v-col>
                  </v-row>
                       
                    </template>
                    <template v-slot:item.status="{ item }">
                    <v-btn
                        depressed
                        color="primary"
                        v-if="item.status == 'Update'"
                        @click="approveItem(item)"
                    >
                        {{ item.status }}
                    </v-btn>
                    <v-btn
                        depressed
                        color="primary"
                        v-else
                        disabled
                        @click="approveItem(item)"
                    >
                        {{ item.status }}
                    </v-btn>
                </template>
            </v-data-table>
          </v-col>


        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>
<script>
import WalletPopUp from "./WalletPopUp.vue"
import TotalWithdrawal from "./TotalWithdrawal.vue"
import ItcannerHeader from "../ItcannerHeader.vue"
import axios from "axios"
export default({
    name: "Wallet",
    components: {
      WalletPopUp,
      ItcannerHeader,
      TotalWithdrawal
    },
    data() {
        return {
            totalWithdrawal:'0',
            pendingWithdrawal:'0',
            item:'',
            WalletFilterPopUp: {
              filter:false,
              amountSearch:'',
              createdatFrom:'',
              createdatTo:'',
              updatedatFrom:'',
              updatedatTo:'',
              status:[],
            },
            headers: [
          {
            text: 'S. No.',
            align: 'start',
            value: 'sino',sortable: false
          },
          { text: 'RequestID', value: 'requestid',sortable: false },
          { text: 'Amount', value: 'amount' ,sortable: false},
          { text: 'Created At', value: 'createdat',sortable: false },
          { text: 'Last Updated At', value: 'updatedat' ,sortable: false},
          { text: 'Status', value: 'status', sortable: false},
        ],
        desserts: [],
        dialog: false,
        editedIndex: -1,
        editedItem: {
            sino: '',
            requestid: '',
            amount: '',
            createdat: '',
            updatedat: '',
            status: [],
        },
        defaultItem: {
            sino: '',
            requestid: '',
            amount: '',
            createdat: '',
            updatedat: '',
            status: [],
        },
        }
    },
    mounted() {
        this.getWithdrawalData();
    },
    methods: {
      getName(value) {
        this.WalletFilterPopUp = value;
          console.log(value);
          this.getWithdrawalData();
      },
      openFilterPopUp(){
        this.WalletFilterPopUp.filter = true;
      },
       async getWithdrawalData(){
          var statusarray = []
          var statusarraydata = this.WalletFilterPopUp.status
          statusarraydata.forEach((statusText)=>{
            statusarray.push(statusText == 'Pending' ? 0 : statusText == 'Processing' ? 1 : statusText == 'Completed' ? 2 : statusText == 'failed' ? 3 : '');
          })
              this.$store.dispatch("setLoader");
            await axios.get('my-withdrawal',{
                  params: {
                    amount: this.WalletFilterPopUp.amountSearch,
                    created_at_from: this.WalletFilterPopUp.createdatFrom,
                    created_at_to: this.WalletFilterPopUp.createdatTo,
                    updated_at_from: this.WalletFilterPopUp.updatedatFrom,
                    updated_at_to: this.WalletFilterPopUp.updatedatTo,
                    status: statusarray,
                  }
                })
              .then(response => {
                console.log(response.data.data);
                if(response.data.status == true){
                  this.desserts = [];
                  var allWithdrawals = response.data.data;
                  allWithdrawals.forEach((withdrawal,index)=>{
                  this.desserts.push(
                      {
                          sino: index+1,
                          requestid: withdrawal.request_id,
                          amount: withdrawal.currency + " " +withdrawal.amount,
                          createdat: withdrawal.created_at,
                          updatedat: withdrawal.updated_at,
                          status: withdrawal.status == 0 ? 'Pending' : withdrawal.status == 1 ? 'Processing' : withdrawal.status == 2 ? 'Completed' : 'Failed',
                      },
                    );
                  });
                }
                else{
                  
                }
              });
              this.$store.dispatch("hideLoader");

        },
    },
})
</script>
<style>
.text-align-right{
  text-align: right;
}
</style>
