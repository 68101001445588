<template>
    <v-toolbar
        flat
    >
        <v-dialog persistent v-model="dialogFilter" max-width="500px">
            <v-card>
                <v-btn depressed
                color="error"
                class="float-right ma-3"
                @click="closeApprove">X</v-btn>
                <v-card-title>
                    Filters
                </v-card-title>
                
                <v-container class="pa-6">

                    <v-text-field
                    v-model="filters.amountSearch"
                    label="Search by Amount"
                    outlined
                    ></v-text-field>
                    
                    <v-list-item-title class="pb-2 font-weight-bold">
                        Created At
                    </v-list-item-title>
                    <div class="row">
                        <div class="col-6">
                            From<br>
                            <v-text-field type="date" v-model="filters.createdatFrom">
                            </v-text-field>
                        </div>
                        
                        <div class="col-6">
                            To<br>
                            <v-text-field type="date" v-model="filters.createdatTo">
                            </v-text-field>
                        </div>
                    </div>

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Updated At
                    </v-list-item-title>
                    <div class="row">
                        <div class="col-6">
                            From<br>
                            <v-text-field type="date" v-model="filters.updatedatFrom">
                            </v-text-field>
                        </div>
                        
                        <div class="col-6">
                            To<br>
                            <v-text-field type="date" v-model="filters.updatedatTo">
                            </v-text-field>
                        </div>
                    </div>

                    <v-list-item-title class="pb-2 font-weight-bold">
                        Status
                    </v-list-item-title>
                    <v-row class="pb-6 text-center">
                        <v-col
                        v-for="action in actions"
                        :key="action">
                        <v-btn 
                        @click="statusFilter(action)"
                        :style="[filters.status.includes(action) ? {'background':'#6993ff'} : {'background': '#FFF'}]">
                            {{action}}
                        </v-btn>
                        </v-col>
                    </v-row>

                    <v-row class="pb-6">
                        <v-col
                        cols="6"
                        sm="6"
                        class="text-right">
                            <v-btn @click="applyFilter">
                                Apply
                            </v-btn>
                        </v-col>

                        <v-col
                        cols="6"
                        sm="6"
                        class="text-left">
                            <v-btn @click="clearFilter">
                                clear
                            </v-btn>
                        </v-col>
                    </v-row>
                    
                </v-container>
            </v-card>
        </v-dialog>
    </v-toolbar>
</template>
<script>

    export default({
        name: "WalletPopUp",
        props:[
            'WalletFilterPopUp',
            'filterData'
        ],
        data() {
            return {
                filters:this.filterData,
                dialogFilter:true,
                allBrands: ['Foo', 'Bar', 'Fizz', 'Buzz'],
                allCause: ['Foo', 'Bar', 'Fizz', 'Buzz'],
                actions: ['Pending','Processing','Completed'],
            }
        },
        methods: {
            clearFilter(){
                this.filters.amountSearch = '';
                this.filters.createdatFrom = '';
                this.filters.createdatTo = '';
                this.filters.updatedatFrom = '';
                this.filters.updatedatTo = '';
                this.filters.status = [];
            },
            applyFilter(){
                console.log(this.filters.amountSearch + '-' + this.filters.createdatFrom + '-' +this.filters.updatedatFrom);
                this.closeApprove();
            },
            statusFilter(status){
                console.log(this.filters.status.lastIndexOf(status));
                if(this.filters.status.lastIndexOf(status) == -1){
                    this.filters.status.push(status);
                }
                else{
                    this.filters.status.splice(this.filters.status.lastIndexOf(status), 1)
                }
            },
            closeApprove () {
                this.filters.filter = false;
                this.$emit('WalletFilterPopUp', this.filters);
            },
        },
    })
</script>